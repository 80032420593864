type Props = {
  src: string;
  poster: string;
}

export default function VideoPlayer({ src, poster }: Props) {
  return (
    <div className="sm:flex-1 sm:flex sm:items-center sm:justify-center sm:max-h-[53dvh] xl:h-auto xl:inline xl:max-h-full sm:bg-black xl:bg-transparent">
      <div className="sm:max-h-[53dvh] xl:max-h-full">
        <video className="sm:max-h-[53dvh] xl:max-h-full" controls disablePictureInPicture
          controlsList="nodownload noplaybackrate"
          poster = {poster}
          autoPlay
        >
          <source src={src} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
